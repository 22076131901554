import * as amplmutations from '@/graphql/mutations'
import * as queries from '@/graphql/queries'
import { API, graphqlOperation } from 'aws-amplify'

const state = {
  loading: false,
  semaphores: {},
  initialFetchDone: false,
  data: [],
  subscription: null,
  ghostTag: []
}

const getters = {
  getInitialFetchDone(state) {
    return state.initialFetchDone
  },
  getData(state) {
    return state.data || []
  }
}

const actions = {
  async list({ commit } /*, opts*/) {
    const data = await API.graphql(graphqlOperation(queries.listTags))
    console.log('Carico i tag', data.data.listTags)
    commit('SET_DATA', data.data.listTags.items)
  },
  // eslint-disable-next-line no-unused-vars
  async getTag({ state }, id) {
    const data = await API.graphql(graphqlOperation(queries.getTag, { id: id }))
    console.log('Single Tag ', data.data.getTag)
    return data.data.getTag
  },
  async createTagStructure({ commit }, data) {
    commit('SET_LOADING', true)
    try {
      const newData = await API.graphql(
        graphqlOperation(amplmutations.createTagStructures, {
          input: data
        })
      )

      //commit("SET_DATA");

      return newData
    } catch (error) {
      console.log('Error during TAG STRUCTURE creation ', error)
      setTimeout(function() {
        state.semaphores.created = false
      }, 2000)
    } finally {
      commit('SET_LOADING', false)
    }
  },
  async createTagImage({ commit }, data) {
    commit('SET_LOADING', true)

    console.log('CREATE TAG IMAGE', data)

    try {
      const newData = await API.graphql(
        graphqlOperation(amplmutations.createTagImages, {
          input: data
        })
      )

      return newData
    } catch (error) {
      console.log('Error during TAG IMAGE creation ', error)
      setTimeout(function() {
        state.semaphores.created = false
      }, 2000)
    } finally {
      commit('SET_LOADING', false)
    }
  },
  async create({ commit }, data) {
    state.loading = true
    try {
      const newData = await API.graphql(
        graphqlOperation(amplmutations.createTag, {
          input: data
        })
      )

      commit('CREATE_DATA')
      return newData.data.createTag
    } catch (error) {
      console.log('USER IS NOT AUTHORIZED', error.data)
    }
    setTimeout(function() {
      state.semaphores.created = false
    }, 2000)
  },

  async deleteTagStructure({ commit }, id) {
    state.loading = true

    await API.graphql(
      graphqlOperation(amplmutations.deleteTagStructures, {
        input: {
          id: id
        }
      })
    )
    commit('DELETE_DATA')
    setTimeout(function() {
      state.semaphores.deleted = false
    }, 2000)
  },

  async deleteTagImage({ commit }, id) {
    state.loading = true

    await API.graphql(
      graphqlOperation(amplmutations.deleteTagImages, {
        input: {
          id: id
        }
      })
    )
    commit('DELETE_DATA')
    setTimeout(function() {
      state.semaphores.deleted = false
    }, 2000)
  },

  async update({ commit }, data) {
    state.loading = true
    await API.graphql(
      graphqlOperation(amplmutations.updateStructure, {
        input: data.updated
      })
    )

    commit('UPDATE_DATA')
    setTimeout(function() {
      state.semaphores.updated = false
    }, 2000)
  }
}

const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading
  },
  SET_DATA(state, data) {
    state.data = data
    state.initialFetchDone = true
  },
  SET_GHOST(state, data) {
    state.ghostTag = data
  },

  CREATE_DATA(state) {
    state.loading = false
    state.semaphores.created = true
  },

  DELETE_DATA(state) {
    state.loading = false
    state.semaphores.deleted = true
  },

  UPDATE_DATA(state) {
    state.loading = false
    state.semaphores.updated = true
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
