import Amplify, { Auth } from 'aws-amplify'
import 'tailwindcss/tailwind.css'
import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import App from './App.vue'
import awsconfig from './aws-exports'
import vuetify from './plugins/vuetify'
import routes from './routes'
import StoreResource from './store'

Amplify.configure(awsconfig)

Vue.config.productionTip = false

Vue.use(Vuex)

export const store = new StoreResource().Store(Vuex).store

export const router = new VueRouter({
  mode: 'history',
  routes // short for `routes: routes`
})

router.beforeResolve((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    console.log('Routes requires auth', to)
    // eslint-disable-next-line no-unused-vars
    let user

    Auth.currentAuthenticatedUser()
      .then(data => {
        if (data && data.signInUserSession) {
          user = data
        }
        console.log('%c AAAAAAUTH', 'font-size:2rem; color:tomato', user)
        next()
      })
      .catch(e => {
        console.log('%c AAAAAAUTH', 'font-size:2rem; color:tomato', e)
        next({
          path: `/login`,
          query: {
            redirect: to.fullPath
          }
        })
      })
  }
  next()
})

Vue.use(VueRouter)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
