/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const structuresByTypeByStatus = /* GraphQL */ `
  query StructuresByTypeByStatus(
    $type: StructureType
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStructureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    structuresByTypeByStatus(
      type: $type
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        title
        description
        excerpt
        address
        lat
        lng
        locale
        highlight
        categorySlug
        pageSlug
        slug
        bookings {
          items {
            id
            startDate
            endDate
            fullName
            email
            adults
            children
            structureID
            createdAt
            updatedAt
            status
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
        status
        images {
          items {
            id
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const structuresByTitleByType = /* GraphQL */ `
  query StructuresByTitleByType(
    $type: StructureType
    $title: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStructureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    structuresByTitleByType(
      type: $type
      title: $title
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        title
        description
        excerpt
        address
        lat
        lng
        locale
        highlight
        categorySlug
        pageSlug
        slug
        bookings {
          items {
            id
            startDate
            endDate
            fullName
            email
            adults
            children
            structureID
            createdAt
            updatedAt
            status
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
        status
        images {
          items {
            id
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getStructure = /* GraphQL */ `
  query GetStructure($id: ID!) {
    getStructure(id: $id) {
      id
      type
      title
      description
      excerpt
      address
      lat
      lng
      locale
      highlight
      categorySlug
      pageSlug
      slug
      bookings {
        items {
          id
          startDate
          endDate
          fullName
          email
          adults
          children
          structureID
          createdAt
          updatedAt
          status
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
      status
      images {
        items {
          id
          createdAt
          updatedAt
          structure {
            id
            type
            title
            description
            excerpt
            address
            lat
            lng
            locale
            highlight
            categorySlug
            pageSlug
            slug
            createdAt
            updatedAt
            owner
            status
          }
          owner
          image {
            id
            title
            description
            createdAt
            updatedAt
            owner
          }
        }
        nextToken
      }
      tags {
        items {
          id
          createdAt
          updatedAt
          structure {
            id
            type
            title
            description
            excerpt
            address
            lat
            lng
            locale
            highlight
            categorySlug
            pageSlug
            slug
            createdAt
            updatedAt
            owner
            status
          }
          owner
          tag {
            id
            label
            createdAt
            updatedAt
            owner
          }
        }
        nextToken
      }
    }
  }
`;
export const listStructures = /* GraphQL */ `
  query ListStructures(
    $filter: ModelStructureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStructures(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        title
        description
        excerpt
        address
        lat
        lng
        locale
        highlight
        categorySlug
        pageSlug
        slug
        bookings {
          items {
            id
            startDate
            endDate
            fullName
            email
            adults
            children
            structureID
            createdAt
            updatedAt
            status
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
        status
        images {
          items {
            id
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getRoom = /* GraphQL */ `
  query GetRoom($id: ID!) {
    getRoom(id: $id) {
      id
      title
      description
      excerpt
      maxPeopleNumber
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listRooms = /* GraphQL */ `
  query ListRooms(
    $filter: ModelRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRooms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        excerpt
        maxPeopleNumber
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const listTags = /* GraphQL */ `
  query ListTags(
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        label
        createdAt
        updatedAt
        structures {
          items {
            id
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        images {
          items {
            id
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        owner
      }
      nextToken
    }
  }
`;
export const getTag = /* GraphQL */ `
  query GetTag($id: ID!) {
    getTag(id: $id) {
      id
      label
      createdAt
      updatedAt
      structures {
        items {
          id
          createdAt
          updatedAt
          structure {
            id
            type
            title
            description
            excerpt
            address
            lat
            lng
            locale
            highlight
            categorySlug
            pageSlug
            slug
            createdAt
            updatedAt
            owner
            status
          }
          owner
          tag {
            id
            label
            createdAt
            updatedAt
            owner
          }
        }
        nextToken
      }
      images {
        items {
          id
          createdAt
          updatedAt
          owner
          tag {
            id
            label
            createdAt
            updatedAt
            owner
          }
          image {
            id
            title
            description
            createdAt
            updatedAt
            owner
          }
        }
        nextToken
      }
      owner
    }
  }
`;
export const byTag = /* GraphQL */ `
  query ByTag(
    $label: String
    $sortDirection: ModelSortDirection
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byTag(
      label: $label
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        label
        createdAt
        updatedAt
        structures {
          items {
            id
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        images {
          items {
            id
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        owner
      }
      nextToken
    }
  }
`;
export const listImages = /* GraphQL */ `
  query ListImages(
    $filter: ModelImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        createdAt
        updatedAt
        structures {
          items {
            id
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        owner
      }
      nextToken
    }
  }
`;
export const getImage = /* GraphQL */ `
  query GetImage($id: ID!) {
    getImage(id: $id) {
      id
      title
      description
      createdAt
      updatedAt
      structures {
        items {
          id
          createdAt
          updatedAt
          structure {
            id
            type
            title
            description
            excerpt
            address
            lat
            lng
            locale
            highlight
            categorySlug
            pageSlug
            slug
            createdAt
            updatedAt
            owner
            status
          }
          owner
          image {
            id
            title
            description
            createdAt
            updatedAt
            owner
          }
        }
        nextToken
      }
      tags {
        items {
          id
          createdAt
          updatedAt
          owner
          tag {
            id
            label
            createdAt
            updatedAt
            owner
          }
          image {
            id
            title
            description
            createdAt
            updatedAt
            owner
          }
        }
        nextToken
      }
      owner
    }
  }
`;
export const getBooking = /* GraphQL */ `
  query GetBooking($id: ID!) {
    getBooking(id: $id) {
      id
      startDate
      endDate
      fullName
      email
      adults
      children
      structureID
      createdAt
      updatedAt
      status
    }
  }
`;
export const listBookings = /* GraphQL */ `
  query ListBookings(
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        startDate
        endDate
        fullName
        email
        adults
        children
        structureID
        createdAt
        updatedAt
        status
      }
      nextToken
    }
  }
`;
