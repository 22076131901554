/* eslint-disable */
// TODO: [ITR-185] search through structures
export const listStructures = /* GraphQL */ `
  query ListStructures(
    $filter: ModelStructureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStructures(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        tags {
          items {
            tag {
              id
              label
            }
            id
          }
        }
        status
        address
        highlight
        pageSlug
        description
        excerpt
        lat
        lng
        type
        owner
        images {
          items {
            id
            image {
              id
              description
              title
            }
          }
        }
      }
    }
  }
`;


