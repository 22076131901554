/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createStructure = /* GraphQL */ `
  mutation CreateStructure(
    $input: CreateStructureInput!
    $condition: ModelStructureConditionInput
  ) {
    createStructure(input: $input, condition: $condition) {
      id
      type
      title
      description
      excerpt
      address
      lat
      lng
      locale
      highlight
      categorySlug
      pageSlug
      slug
      bookings {
        items {
          id
          startDate
          endDate
          fullName
          email
          adults
          children
          structureID
          createdAt
          updatedAt
          status
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
      status
      images {
        items {
          id
          createdAt
          updatedAt
          structure {
            id
            type
            title
            description
            excerpt
            address
            lat
            lng
            locale
            highlight
            categorySlug
            pageSlug
            slug
            createdAt
            updatedAt
            owner
            status
          }
          owner
          image {
            id
            title
            description
            createdAt
            updatedAt
            owner
          }
        }
        nextToken
      }
      tags {
        items {
          id
          createdAt
          updatedAt
          structure {
            id
            type
            title
            description
            excerpt
            address
            lat
            lng
            locale
            highlight
            categorySlug
            pageSlug
            slug
            createdAt
            updatedAt
            owner
            status
          }
          owner
          tag {
            id
            label
            createdAt
            updatedAt
            owner
          }
        }
        nextToken
      }
    }
  }
`;
export const updateStructure = /* GraphQL */ `
  mutation UpdateStructure(
    $input: UpdateStructureInput!
    $condition: ModelStructureConditionInput
  ) {
    updateStructure(input: $input, condition: $condition) {
      id
      type
      title
      description
      excerpt
      address
      lat
      lng
      locale
      highlight
      categorySlug
      pageSlug
      slug
      bookings {
        items {
          id
          startDate
          endDate
          fullName
          email
          adults
          children
          structureID
          createdAt
          updatedAt
          status
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
      status
      images {
        items {
          id
          createdAt
          updatedAt
          structure {
            id
            type
            title
            description
            excerpt
            address
            lat
            lng
            locale
            highlight
            categorySlug
            pageSlug
            slug
            createdAt
            updatedAt
            owner
            status
          }
          owner
          image {
            id
            title
            description
            createdAt
            updatedAt
            owner
          }
        }
        nextToken
      }
      tags {
        items {
          id
          createdAt
          updatedAt
          structure {
            id
            type
            title
            description
            excerpt
            address
            lat
            lng
            locale
            highlight
            categorySlug
            pageSlug
            slug
            createdAt
            updatedAt
            owner
            status
          }
          owner
          tag {
            id
            label
            createdAt
            updatedAt
            owner
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteStructure = /* GraphQL */ `
  mutation DeleteStructure(
    $input: DeleteStructureInput!
    $condition: ModelStructureConditionInput
  ) {
    deleteStructure(input: $input, condition: $condition) {
      id
      type
      title
      description
      excerpt
      address
      lat
      lng
      locale
      highlight
      categorySlug
      pageSlug
      slug
      bookings {
        items {
          id
          startDate
          endDate
          fullName
          email
          adults
          children
          structureID
          createdAt
          updatedAt
          status
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
      status
      images {
        items {
          id
          createdAt
          updatedAt
          structure {
            id
            type
            title
            description
            excerpt
            address
            lat
            lng
            locale
            highlight
            categorySlug
            pageSlug
            slug
            createdAt
            updatedAt
            owner
            status
          }
          owner
          image {
            id
            title
            description
            createdAt
            updatedAt
            owner
          }
        }
        nextToken
      }
      tags {
        items {
          id
          createdAt
          updatedAt
          structure {
            id
            type
            title
            description
            excerpt
            address
            lat
            lng
            locale
            highlight
            categorySlug
            pageSlug
            slug
            createdAt
            updatedAt
            owner
            status
          }
          owner
          tag {
            id
            label
            createdAt
            updatedAt
            owner
          }
        }
        nextToken
      }
    }
  }
`;
export const createRoom = /* GraphQL */ `
  mutation CreateRoom(
    $input: CreateRoomInput!
    $condition: ModelRoomConditionInput
  ) {
    createRoom(input: $input, condition: $condition) {
      id
      title
      description
      excerpt
      maxPeopleNumber
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateRoom = /* GraphQL */ `
  mutation UpdateRoom(
    $input: UpdateRoomInput!
    $condition: ModelRoomConditionInput
  ) {
    updateRoom(input: $input, condition: $condition) {
      id
      title
      description
      excerpt
      maxPeopleNumber
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteRoom = /* GraphQL */ `
  mutation DeleteRoom(
    $input: DeleteRoomInput!
    $condition: ModelRoomConditionInput
  ) {
    deleteRoom(input: $input, condition: $condition) {
      id
      title
      description
      excerpt
      maxPeopleNumber
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createImageStructures = /* GraphQL */ `
  mutation CreateImageStructures(
    $input: CreateImageStructuresInput!
    $condition: ModelImageStructuresConditionInput
  ) {
    createImageStructures(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      structure {
        id
        type
        title
        description
        excerpt
        address
        lat
        lng
        locale
        highlight
        categorySlug
        pageSlug
        slug
        bookings {
          items {
            id
            startDate
            endDate
            fullName
            email
            adults
            children
            structureID
            createdAt
            updatedAt
            status
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
        status
        images {
          items {
            id
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
      }
      owner
      image {
        id
        title
        description
        createdAt
        updatedAt
        structures {
          items {
            id
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        owner
      }
    }
  }
`;
export const updateImageStructures = /* GraphQL */ `
  mutation UpdateImageStructures(
    $input: UpdateImageStructuresInput!
    $condition: ModelImageStructuresConditionInput
  ) {
    updateImageStructures(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      structure {
        id
        type
        title
        description
        excerpt
        address
        lat
        lng
        locale
        highlight
        categorySlug
        pageSlug
        slug
        bookings {
          items {
            id
            startDate
            endDate
            fullName
            email
            adults
            children
            structureID
            createdAt
            updatedAt
            status
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
        status
        images {
          items {
            id
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
      }
      owner
      image {
        id
        title
        description
        createdAt
        updatedAt
        structures {
          items {
            id
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        owner
      }
    }
  }
`;
export const deleteImageStructures = /* GraphQL */ `
  mutation DeleteImageStructures(
    $input: DeleteImageStructuresInput!
    $condition: ModelImageStructuresConditionInput
  ) {
    deleteImageStructures(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      structure {
        id
        type
        title
        description
        excerpt
        address
        lat
        lng
        locale
        highlight
        categorySlug
        pageSlug
        slug
        bookings {
          items {
            id
            startDate
            endDate
            fullName
            email
            adults
            children
            structureID
            createdAt
            updatedAt
            status
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
        status
        images {
          items {
            id
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
      }
      owner
      image {
        id
        title
        description
        createdAt
        updatedAt
        structures {
          items {
            id
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        owner
      }
    }
  }
`;
export const createTagStructures = /* GraphQL */ `
  mutation CreateTagStructures(
    $input: CreateTagStructuresInput!
    $condition: ModelTagStructuresConditionInput
  ) {
    createTagStructures(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      structure {
        id
        type
        title
        description
        excerpt
        address
        lat
        lng
        locale
        highlight
        categorySlug
        pageSlug
        slug
        bookings {
          items {
            id
            startDate
            endDate
            fullName
            email
            adults
            children
            structureID
            createdAt
            updatedAt
            status
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
        status
        images {
          items {
            id
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
      }
      owner
      tag {
        id
        label
        createdAt
        updatedAt
        structures {
          items {
            id
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        images {
          items {
            id
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        owner
      }
    }
  }
`;
export const updateTagStructures = /* GraphQL */ `
  mutation UpdateTagStructures(
    $input: UpdateTagStructuresInput!
    $condition: ModelTagStructuresConditionInput
  ) {
    updateTagStructures(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      structure {
        id
        type
        title
        description
        excerpt
        address
        lat
        lng
        locale
        highlight
        categorySlug
        pageSlug
        slug
        bookings {
          items {
            id
            startDate
            endDate
            fullName
            email
            adults
            children
            structureID
            createdAt
            updatedAt
            status
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
        status
        images {
          items {
            id
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
      }
      owner
      tag {
        id
        label
        createdAt
        updatedAt
        structures {
          items {
            id
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        images {
          items {
            id
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        owner
      }
    }
  }
`;
export const deleteTagStructures = /* GraphQL */ `
  mutation DeleteTagStructures(
    $input: DeleteTagStructuresInput!
    $condition: ModelTagStructuresConditionInput
  ) {
    deleteTagStructures(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      structure {
        id
        type
        title
        description
        excerpt
        address
        lat
        lng
        locale
        highlight
        categorySlug
        pageSlug
        slug
        bookings {
          items {
            id
            startDate
            endDate
            fullName
            email
            adults
            children
            structureID
            createdAt
            updatedAt
            status
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
        status
        images {
          items {
            id
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
      }
      owner
      tag {
        id
        label
        createdAt
        updatedAt
        structures {
          items {
            id
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        images {
          items {
            id
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        owner
      }
    }
  }
`;
export const createTagImages = /* GraphQL */ `
  mutation CreateTagImages(
    $input: CreateTagImagesInput!
    $condition: ModelTagImagesConditionInput
  ) {
    createTagImages(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      owner
      tag {
        id
        label
        createdAt
        updatedAt
        structures {
          items {
            id
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        images {
          items {
            id
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        owner
      }
      image {
        id
        title
        description
        createdAt
        updatedAt
        structures {
          items {
            id
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        owner
      }
    }
  }
`;
export const updateTagImages = /* GraphQL */ `
  mutation UpdateTagImages(
    $input: UpdateTagImagesInput!
    $condition: ModelTagImagesConditionInput
  ) {
    updateTagImages(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      owner
      tag {
        id
        label
        createdAt
        updatedAt
        structures {
          items {
            id
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        images {
          items {
            id
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        owner
      }
      image {
        id
        title
        description
        createdAt
        updatedAt
        structures {
          items {
            id
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        owner
      }
    }
  }
`;
export const deleteTagImages = /* GraphQL */ `
  mutation DeleteTagImages(
    $input: DeleteTagImagesInput!
    $condition: ModelTagImagesConditionInput
  ) {
    deleteTagImages(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      owner
      tag {
        id
        label
        createdAt
        updatedAt
        structures {
          items {
            id
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        images {
          items {
            id
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        owner
      }
      image {
        id
        title
        description
        createdAt
        updatedAt
        structures {
          items {
            id
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        owner
      }
    }
  }
`;
export const createTag = /* GraphQL */ `
  mutation CreateTag(
    $input: CreateTagInput!
    $condition: ModelTagConditionInput
  ) {
    createTag(input: $input, condition: $condition) {
      id
      label
      createdAt
      updatedAt
      structures {
        items {
          id
          createdAt
          updatedAt
          structure {
            id
            type
            title
            description
            excerpt
            address
            lat
            lng
            locale
            highlight
            categorySlug
            pageSlug
            slug
            createdAt
            updatedAt
            owner
            status
          }
          owner
          tag {
            id
            label
            createdAt
            updatedAt
            owner
          }
        }
        nextToken
      }
      images {
        items {
          id
          createdAt
          updatedAt
          owner
          tag {
            id
            label
            createdAt
            updatedAt
            owner
          }
          image {
            id
            title
            description
            createdAt
            updatedAt
            owner
          }
        }
        nextToken
      }
      owner
    }
  }
`;
export const updateTag = /* GraphQL */ `
  mutation UpdateTag(
    $input: UpdateTagInput!
    $condition: ModelTagConditionInput
  ) {
    updateTag(input: $input, condition: $condition) {
      id
      label
      createdAt
      updatedAt
      structures {
        items {
          id
          createdAt
          updatedAt
          structure {
            id
            type
            title
            description
            excerpt
            address
            lat
            lng
            locale
            highlight
            categorySlug
            pageSlug
            slug
            createdAt
            updatedAt
            owner
            status
          }
          owner
          tag {
            id
            label
            createdAt
            updatedAt
            owner
          }
        }
        nextToken
      }
      images {
        items {
          id
          createdAt
          updatedAt
          owner
          tag {
            id
            label
            createdAt
            updatedAt
            owner
          }
          image {
            id
            title
            description
            createdAt
            updatedAt
            owner
          }
        }
        nextToken
      }
      owner
    }
  }
`;
export const deleteTag = /* GraphQL */ `
  mutation DeleteTag(
    $input: DeleteTagInput!
    $condition: ModelTagConditionInput
  ) {
    deleteTag(input: $input, condition: $condition) {
      id
      label
      createdAt
      updatedAt
      structures {
        items {
          id
          createdAt
          updatedAt
          structure {
            id
            type
            title
            description
            excerpt
            address
            lat
            lng
            locale
            highlight
            categorySlug
            pageSlug
            slug
            createdAt
            updatedAt
            owner
            status
          }
          owner
          tag {
            id
            label
            createdAt
            updatedAt
            owner
          }
        }
        nextToken
      }
      images {
        items {
          id
          createdAt
          updatedAt
          owner
          tag {
            id
            label
            createdAt
            updatedAt
            owner
          }
          image {
            id
            title
            description
            createdAt
            updatedAt
            owner
          }
        }
        nextToken
      }
      owner
    }
  }
`;
export const createImage = /* GraphQL */ `
  mutation CreateImage(
    $input: CreateImageInput!
    $condition: ModelImageConditionInput
  ) {
    createImage(input: $input, condition: $condition) {
      id
      title
      description
      createdAt
      updatedAt
      structures {
        items {
          id
          createdAt
          updatedAt
          structure {
            id
            type
            title
            description
            excerpt
            address
            lat
            lng
            locale
            highlight
            categorySlug
            pageSlug
            slug
            createdAt
            updatedAt
            owner
            status
          }
          owner
          image {
            id
            title
            description
            createdAt
            updatedAt
            owner
          }
        }
        nextToken
      }
      tags {
        items {
          id
          createdAt
          updatedAt
          owner
          tag {
            id
            label
            createdAt
            updatedAt
            owner
          }
          image {
            id
            title
            description
            createdAt
            updatedAt
            owner
          }
        }
        nextToken
      }
      owner
    }
  }
`;
export const updateImage = /* GraphQL */ `
  mutation UpdateImage(
    $input: UpdateImageInput!
    $condition: ModelImageConditionInput
  ) {
    updateImage(input: $input, condition: $condition) {
      id
      title
      description
      createdAt
      updatedAt
      structures {
        items {
          id
          createdAt
          updatedAt
          structure {
            id
            type
            title
            description
            excerpt
            address
            lat
            lng
            locale
            highlight
            categorySlug
            pageSlug
            slug
            createdAt
            updatedAt
            owner
            status
          }
          owner
          image {
            id
            title
            description
            createdAt
            updatedAt
            owner
          }
        }
        nextToken
      }
      tags {
        items {
          id
          createdAt
          updatedAt
          owner
          tag {
            id
            label
            createdAt
            updatedAt
            owner
          }
          image {
            id
            title
            description
            createdAt
            updatedAt
            owner
          }
        }
        nextToken
      }
      owner
    }
  }
`;
export const deleteImage = /* GraphQL */ `
  mutation DeleteImage(
    $input: DeleteImageInput!
    $condition: ModelImageConditionInput
  ) {
    deleteImage(input: $input, condition: $condition) {
      id
      title
      description
      createdAt
      updatedAt
      structures {
        items {
          id
          createdAt
          updatedAt
          structure {
            id
            type
            title
            description
            excerpt
            address
            lat
            lng
            locale
            highlight
            categorySlug
            pageSlug
            slug
            createdAt
            updatedAt
            owner
            status
          }
          owner
          image {
            id
            title
            description
            createdAt
            updatedAt
            owner
          }
        }
        nextToken
      }
      tags {
        items {
          id
          createdAt
          updatedAt
          owner
          tag {
            id
            label
            createdAt
            updatedAt
            owner
          }
          image {
            id
            title
            description
            createdAt
            updatedAt
            owner
          }
        }
        nextToken
      }
      owner
    }
  }
`;
export const updateBooking = /* GraphQL */ `
  mutation UpdateBooking(
    $input: UpdateBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    updateBooking(input: $input, condition: $condition) {
      id
      startDate
      endDate
      fullName
      email
      adults
      children
      structureID
      createdAt
      updatedAt
      status
    }
  }
`;
export const deleteBooking = /* GraphQL */ `
  mutation DeleteBooking(
    $input: DeleteBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    deleteBooking(input: $input, condition: $condition) {
      id
      startDate
      endDate
      fullName
      email
      adults
      children
      structureID
      createdAt
      updatedAt
      status
    }
  }
`;
export const createBooking = /* GraphQL */ `
  mutation CreateBooking(
    $input: CreateBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    createBooking(input: $input, condition: $condition) {
      id
      startDate
      endDate
      fullName
      email
      adults
      children
      structureID
      createdAt
      updatedAt
      status
    }
  }
`;
