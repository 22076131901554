import bookings from './entities/Bookings/Bookings.js'
import structures from './entities/Properties/Properties.js'
import tags from './entities/Tags/Tags'
import auth from './_utils/Auth.js'
import drawer from './_utils/Drawer.js'
import header from './_utils/Header.js'
import media from './entities/Media/Media.js'

export default class StoreResource {
  Store(Vuex) {
    return {
      store: new Vuex.Store({
        modules: {
          auth,
          drawer,
          header,
          media,
          structures,
          tags,
          bookings
        }
      })
    }
  }
}
