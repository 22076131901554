module.exports = {
  purge: ['./src/**/*.html', './src/**/*.vue', './src/**/*.jsx'],
  darkMode: false, // or 'media' or 'class'
  important: true,
  theme: {
    extend: {
      colors: {
        primary: {
          '100': '#f1f4fe',
          '200': '#b4c8f9',
          '300': '#7da8f3',
          '400': '#428beb',
          '500': '#1975d2',
          '600': '#1254aa',
          '700': '#0c3882',
          '800': '#072059',
          '900': '#030e30'
        },
        secondary: {
          '100': '#f8f7f7',
          '200': '#cdc8c6',
          '300': '#a09998',
          '400': '#726e6e',
          '500': '#424242',
          '600': '#393737',
          '700': '#302d2c',
          '800': '#262222',
          '900': '#1c1917'
        },
        accent: {
          '100': '#f0f1ff',
          '200': '#d6deff',
          '300': '#b8c9ff',
          '400': '#9ebdff',
          '500': '#85b4ff',
          '600': '#2e70ff',
          '700': '#0037db',
          '800': '#001885',
          '900': '#000633'
        },
        error: {
          '100': '#fff4f0',
          '200': '#ffd2c7',
          '300': '#ffafa3',
          '400': '#ff837a',
          '500': '#ff5252',
          '600': '#ff1b0a',
          '700': '#c21a00',
          '800': '#7a1800',
          '900': '#330e00'
        },
        info: {
          '100': '#f0f4ff',
          '200': '#b9d1fd',
          '300': '#88b8fb',
          '400': '#54a3f8',
          '500': '#2094f3',
          '600': '#096ad2',
          '700': '#05459e',
          '800': '#022669',
          '900': '#010f32'
        },
        success: {
          '100': '#f4fbf6',
          '200': '#c7eacf',
          '300': '#9cd8a6',
          '400': '#72c57b',
          '500': '#4cae4f',
          '600': '#3a8d43',
          '700': '#2b6e36',
          '800': '#1c4a27',
          '900': '#0d2615'
        },
        warning: {
          '100': '#fffff0',
          '200': '#fffbb8',
          '300': '#fff07a',
          '400': '#ffdc42',
          '500': '#ffc105',
          '600': '#d1ab00',
          '700': '#9e8c00',
          '800': '#666100',
          '900': '#323300'
        }
      },
      height: theme => ({
        ...theme('spacing'),
        '104': '28rem'
      })
    }
  },
  variants: {
    extend: {}
  },
  plugins: [require('tailwindcss-debug-screens')]
}
