const state = {
  toggleAccountIsChecked: false,
  toggleOrganizationsIsChecked: false
}

const getters = {
  toggleAccountIsChecked(state) {
    return state.toggleAccountIsChecked
  },
  toggleOrganizationsIsChecked(state) {
    return state.toggleOrganizationsIsChecked
  }
}

const actions = {
  toggleAccountCheckSwitch({ commit }, data) {
    commit('SET_TOGGLE_ACCOUNT', data.checked)
  },
  toggleOrganizationsCheckSwitch({ commit }, data) {
    commit('SET_TOGGLE_ORGANIZATIONS', data.checked)
  },
  closeAllDropdowns({ commit }, target) {
    commit('CLOSE_ALL_DROPDOWNS', target)
  }
}

const mutations = {
  SET_TOGGLE_ACCOUNT(state, checked) {
    state.toggleAccountIsChecked = checked
  },
  CLOSE_ALL_DROPDOWNS(state, params = {}) {
    if (params === {}) return
    let toggleDrawerMenu = params.document.getElementById('menu-toggle')
    if (toggleDrawerMenu && toggleDrawerMenu.checked) {
      toggleDrawerMenu.checked = false
      params.store.dispatch('drawer/toggleCheckSwitch', false)
    }
    if (params.target !== 'account') state.toggleAccountIsChecked = false
    if (params.target !== 'organizations')
      state.toggleOrganizationsIsChecked = false
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
