import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import it from 'vuetify/lib/locale/it'
import tw from '../../tailwind.config'
import '../main.pcss'

Vue.use(Vuetify)
// TODO: create nuoovo task
export default new Vuetify({
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: tw.theme.extend.colors.primary[500], //'#1976D2',
        secondary: tw.theme.extend.colors.secondary[500], //'#424242',
        accent: tw.theme.extend.colors.accent[500], //'#82B1FF',
        error: tw.theme.extend.colors.error[500], //'#FF5252',
        info: tw.theme.extend.colors.info[500], //'#2196F3',
        success: tw.theme.extend.colors.success[500], //'#4CAF50',
        warning: tw.theme.extend.colors.warning[500] //'#FFC107',
      }
    }
  },
  lang: {
    locales: { it },
    current: 'it'
  }
})
