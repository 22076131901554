let routes = [
  { path: '/', redirect: '/dashboard' },
  {
    path: '/dashboard',
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ './entities/Dashboard/Dashboard.vue'
      ),
    name: 'dashboard',
    meta: {
      requiresAuth: true,
      layout: 'Full'
    }
  },
  {
    path: '/login',
    component: () =>
      import(/* webpackChunkName: "login" */ './entities/Auth/Login.vue'),
    name: 'login',
    meta: {
      requiresAuth: false,
      layout: 'Splitted'
    }
  },
  {
    path: '/logout',
    component: () =>
      import(/* webpackChunkName: "login" */ './entities/Auth/Logout.vue'),
    name: 'logout',
    meta: {
      requiresAuth: false,
      layout: 'Splitted'
    }
  },
  {
    path: '/signup',
    component: () =>
      import(/* webpackChunkName: "login" */ './entities/Auth/SignUp.vue'),
    name: 'signup',
    meta: {
      requiresAuth: false,
      layout: 'Splitted'
    }
  },
  {
    path: '/forgot',
    component: () =>
      import(/* webpackChunkName: "login" */ './entities/Auth/Forgot.vue'),
    name: 'forgot',
    meta: {
      requiresAuth: false,
      layout: 'Splitted'
    }
  },
  {
    path: '/reset-password',
    component: () =>
      import(
        /* webpackChunkName: "login" */ './entities/Auth/ResetPassword.vue'
      ),
    name: 'reset-password',
    meta: {
      requiresAuth: false,
      layout: 'Splitted'
    }
  },
  {
    path: '/properties/list',
    name: 'properties',
    component: () =>
      import(
        /* webpackChunkName: "properties" */ './entities/Properties/PropertiesList.vue'
      ),
    meta: {
      name: 'Strutture'
    }
  },
  // {
  //   path: 'search/:q',
  //   name: 'propertysearch',
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "PropertySearch" */ './entities/Properties/PropertiesSearch.vue'
  //     ),
  //   meta: {
  //     name: 'Cerca'
  //   }
  // },
  {
    path: '/properties/create',
    name: 'propertyCreate',
    component: () =>
      import(
        /* webpackChunkName: "PropertyCreate" */ './entities/Properties/PropertyEdit.vue'
      ),
    meta: {
      name: 'Crea Struttura'
    }
  },
  {
    path: '/properties/:id',
    name: 'propertyEdit',
    component: () =>
      import(
        /* webpackChunkName: "PropertyEdit" */ './entities/Properties/PropertyEdit.vue'
      ),
    meta: {
      name: 'Modifica'
    }
  },

  /* -------------------------------------------------------------------------- */
  /*                                  Bookings                                  */
  /* -------------------------------------------------------------------------- */
  {
    path: '/bookings/list',
    name: 'bookingsList',
    component: () =>
      import(
        /* webpackChunkName: "bookingsList" */ './entities/Bookings/BookingsList.vue'
      ),
    meta: {
      name: 'Prenotazioni'
    }
  },
  {
    path: '/bookings/create',
    name: 'createBooking',
    component: () =>
      import(
        /* webpackChunkName: "bookingsCreate" */ './entities/Bookings/BookingEdit.vue'
      )
  },
  {
    path: '/bookings/:id',
    name: 'editBooking',
    component: () =>
      import(
        /* webpackChunkName: "bookingsEdit" */ './entities/Bookings/BookingEdit.vue'
      )
  },

  //   {
  //     path: "/properties/create",
  //     component: () =>
  //       import(
  //         /* webpackChunkName: "propertyCreate" */ "./Properties/PropertyCreate.vue"
  //       ),
  //     name: "propertyCreate"
  //   },
  //   {
  //     path: "/properties/:id",
  //     component: () =>
  //       import(
  //         /* webpackChunkName: "propertyEdit" */ "./Properties/PropertyEdit.vue"
  //       ),
  //     name: "propertyEdit"
  //   },
  {
    path: '/tags',
    component: () =>
      import(/* webpackChunkName: "tags" */ './entities/Tags/Tags.vue'),
    children: [
      { path: '', redirect: 'list' },
      {
        path: 'list',
        component: () =>
          import(
            /* webpackChunkName: "tagsLists" */ './entities/Tags/TagsLists.vue'
          )
      },
      {
        path: 'create',
        component: () =>
          import(
            /* webpackChunkName: "tagsCreate" */ './entities/Tags/TagsCreate.vue'
          ),
        name: 'tagsCreate'
      },
      {
        path: ':id',
        component: () =>
          import(
            /* webpackChunkName: "tagEdit" */ './entities/Tags/TagsEdit.vue'
          ),
        name: 'tagsEdit'
      }
    ]
  },

  {
    path: '/media/list',
    component: () =>
      import(
        /* webpackChunkName: "mediaUpload" */ './entities/Media/MediaList.vue'
      ),
    name: 'mediaList',
    meta: {
      name: 'Media'
    }
  },
  {
    path: '/media/create',
    component: () =>
      import(
        /* webpackChunkName: "mediaUpload" */ './entities/Media/MediaEdit.vue'
      ),
    name: 'mediaCreate'
  },
  {
    path: '/media/:id',
    component: () =>
      import(
        /* webpackChunkName: "mediaEdit" */ './entities/Media/MediaEdit.vue'
      ),
    name: 'mediaEdit'
  },
  {
    path: '/pages/list',
    component: () =>
      import(
        /* webpackChunkName: "mediaUpload" */ './entities/Pages/PagesList.vue'
      ),
    name: 'pageList',
    meta: {
      name: 'Pagine'
    }
  },
  {
    path: '/pages/create',
    component: () =>
      import(
        /* webpackChunkName: "mediaUpload" */ './entities/Pages/PageEdit.vue'
      ),
    name: 'pageCreate'
  },
  {
    path: '/pages/:id',
    component: () =>
      import(
        /* webpackChunkName: "mediaUpload" */ './entities/Pages/PageEdit.vue'
      ),
    name: 'pageEdit'
  }

  // { path: '/pages', component: () =>
  //     import(/* webpackChunkName: "pages" */ './Pages/Pages.vue'),
  //     name: 'pages'
  // },
  // { path: '/requests', component: () =>
  //     import(/* webpackChunkName: "requests" */ './Requests/Requests.vue'),
  //     name: 'requests'
  // },
  // { path: '/contracts', component: () =>
  //     import(/* webpackChunkName: "contracts" */ './Contracts/Contracts.vue'),
  //     name: 'contracts'
  // },
  // { path: '/payments', component: () =>
  //     import(/* webpackChunkName: "payments" */ './Payments/Payments.vue'),
  //     name: 'payments'
  // },
  // { path: '/services', component: () =>
  //     import(/* webpackChunkName: "services" */ './Services/Services.vue'),
  //     name: 'services'
  // }
]

routes.map(route => {
  if (route.meta === undefined) {
    route.meta = { ACL: { public: false } }
  } else {
    if (route.meta.ACL === undefined) {
      route.meta.ACL = { public: false }
    } else {
      if (route.meta.ACL.public === undefined) route.meta.ACL.public = false
    }
  }
})

export default routes
