/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:2ef83e0b-1cd7-4b02-bb89-f4be50a42ace",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_aF8rCV1ix",
    "aws_user_pools_web_client_id": "1skptj4jpe8sgn060f037ae220",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://h3ymxq2ty5bgpeffohbb5zo3mm.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-wxgwry2w45ajjgq7aj37aoho2y",
    "aws_user_files_s3_bucket": "itrinus3admin-assets141215-dev",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
