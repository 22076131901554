import { Auth } from 'aws-amplify'
import { router, store } from '../main.js'

const state = {
  loadingStatus: '',
  error: null,
  errorCode: '',
  userSession: '',
  currentUserData: null,
  email: ''
}

const getters = {
  loadingStatus(state) {
    return state.loadingStatus
  },
  error(state) {
    return state.error
  },
  errorCode(state) {
    return state.errorCode
  },
  userSession(state) {
    return state.userSession
  },
  currentUserData(state) {
    if (state.currentUserData) {
      return state.currentUserData
    }
  },
  token(state) {
    if (!state.currentUserData) return ''
    return state.currentUserData.signInUserSession.accessToken.jwtToken
  },
  isAdmin: state => {
    if (!state.currentUserData) return ''
    return !!(
      state.currentUserData.groups &&
      state.currentUserData.groups.includes('Administrators')
    )
  },
  isLogged(state) {
    return Boolean(state.currentUserData)
  },
  userData(state) {
    if (!state.currentUserData) return {}
    return {
      name: state.currentUserData.signInUserSession.idToken.payload.name,
      family_name:
        state.currentUserData.signInUserSession.idToken.payload.family_name,
      email: state.currentUserData.signInUserSession.idToken.payload.email
    }
  },
  currentEmail(state) {
    if (!state.email) return {}
    return state.email
  }
}

const actions = {
  async signIn({ commit, dispatch }, data) {
    commit('SET_STATUS', 'LOADING')
    commit('SET_ERROR', null)
    commit('SET_ERROR_CODE', null)

    try {
      await Auth.signIn(data.email, data.password)
      let user = dispatch('getCurrentUser')

      router.push('/')
      return user
    } catch (err) {
      console.log('Errore auth', err)
      commit('SET_ERROR', err.message || err)
      commit('SET_ERROR_CODE', err.code || '')
    }
  },
  async signUp({ commit }, data) {
    commit('SET_STATUS', 'LOADING')
    commit('SET_ERROR', null)
    commit('SET_ERROR_CODE', null)

    try {
      console.log('Signin Up! ', data)
      let _signUp = await Auth.signUp({
        username: data.email,
        password: data.password,
        attributes: {
          name: data.name,
          email: data.email,
          family_name: data.family_name
        },
        validationData: [] //optional
      })

      console.log('Sign Up Succeded', _signUp)

      commit('SET_STATUS', 'LOADED')
      store.dispatch('signUp/setEmail', data.email)
    } catch (error) {
      console.log('Errore di Sign up ', error)
      commit('SET_ERROR', error.message || error)
      commit('SET_ERROR_CODE', error.code || '')
    }

    //      .catch(err => commit("SET_ERROR", err.message || err));
  },
  confirmSignUp({ commit }, data) {
    console.log('auth confirm: ' + data.email + ' ' + data.code)
    commit('SET_STATUS', 'LOADING')
    commit('SET_ERROR', null)
    commit('SET_ERROR_CODE', null)

    Auth.confirmSignUp(data.email, data.code, {
      // Optional. Force user confirmation irrespective of existing alias. By default set to True.
      forceAliasCreation: true
    })
      .then(() => {
        commit('SET_STATUS', 'LOADED')
        router.push('login')
      })
      .catch(err => commit('SET_ERROR', err.message || err))
  },
  resendSignUp({ commit }, data) {
    commit('SET_STATUS', 'LOADING')
    commit('SET_ERROR', null)
    commit('SET_ERROR_CODE', null)

    Auth.resendSignUp(data.email)
      .then(() => {
        console.log('code resent successfully')
        commit('SET_STATUS', 'LOADED')
      })
      .catch(() => {
        console.log(err => commit('SET_ERROR', err.message || err))
      })
  },
  async forgotPassword({ commit }, data) {
    commit('SET_STATUS', 'LOADING')
    commit('SET_ERROR', null)
    console.log('Forgot password', data)
    try {
      await Auth.forgotPassword(data.email)
      commit('SET_STATUS', 'LOADED')
      commit('SET_EMAIL', data.email)
      router.push('reset-password')
    } catch (err) {
      commit('SET_ERROR', err.message || err)
    }
  },
  forgotPasswordSubmit({ commit }, data) {
    commit('SET_STATUS', 'LOADING')
    commit('SET_ERROR', null)
    return new Promise((resolve, reject) => {
      Auth.forgotPasswordSubmit(data.email, data.code, data.newPassword)
        .then(response => {
          commit('SET_STATUS', 'LOADED')
          resolve(response)
          router.push('login')
        })
        .catch(err => {
          reject(err)
          commit('SET_ERROR', err.message || err)
        })
    })
  },
  signOut({ commit }) {
    commit('SET_STATUS', 'LOADING')
    commit('SET_ERROR', null)
    console.log('Esco! ')
    Auth.signOut()
      .then(() => {
        commit('SET_STATUS', 'LOADED')
        commit('SET_USER_SESSION', null)
        commit('SET_USER_DATA', null)
        router.push('/')
      })
      .catch(err => commit('SET_ERROR', err.message || err))
  },
  getCurrentSession({ commit }) {
    Auth.currentSession()
      .then(data => commit('SET_USER_SESSION', data))
      .catch(err => commit('SET_ERROR', err.message || err))
  },
  async getCurrentUser({ commit }) {
    console.log('ENTRAAATOOOOO')
    commit('SET_STATUS', 'LOADING')
    commit('SET_ERROR', null)
    try {
      let user = await Auth.currentAuthenticatedUser({
        bypassCache: true
      })
      let _userInfo = { ...user }
      _userInfo.groups =
        user.signInUserSession.accessToken.payload['cognito:groups']
      commit('SET_STATUS', 'LOADED')
      commit('SET_USER_DATA', _userInfo)
      return user
    } catch (error) {
      commit('SET_ERROR', error.message || error)
    }
  }
}

const mutations = {
  SET_STATUS(state, status) {
    state.loadingStatus = status
  },
  SET_ERROR(state, error) {
    state.error = error
  },
  SET_ERROR_CODE(state, code) {
    state.errorCode = code
  },
  SET_USER_SESSION(state, session) {
    state.userSession = session
  },
  SET_USER_DATA(state, data) {
    state.currentUserData = data
  },
  SET_EMAIL(state, data) {
    state.email = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
