// import { DataStore } from '@aws-amplify/datastore';
// import { Structure } from '@/models';

import * as amplmutations from '@/graphql/mutations'
import * as queries from '@/graphql/queries'
import { API, graphqlOperation } from 'aws-amplify'

const state = {
  loading: false,
  semaphores: {},
  initialFetchDone: false,
  data: [],
  subscription: null,
  showModalStatus: false
}

const getters = {
  getInitialFetchDone(state) {
    return state.initialFetchDone
  },
  getData(state) {
    return state.data
  },
  showModalStatus(state) {
    return state.showModalStatus
  }
}

const actions = {
  // async query({commit}) {
  //     const data =  await DataStore.query(Structure);
  //     commit('SET_DATA', data)
  // },
  toggleModalStatus({ commit }, status) {
    commit('SET_SHOW_MODAL_STATUS', status)
  },
  async list({ commit }, opts) {
    const data = await API.graphql(
      graphqlOperation(queries.listImages, {
        filter: opts.filter,
        nextToken: opts.nextToken,
        limit: opts.limit
      })
    )
    console.log(data)
    commit('SET_DATA', data.data.listImages.items)
    return data.data.listImages
  },

  async create({ commit }, data) {
    state.loading = true
    try {
      // const newData = await DataStore.save(
      //     new Structure(data)
      // );
      console.log('Create Image', data)
      const newData = await API.graphql(
        graphqlOperation(amplmutations.createImage, { input: data })
      )
      console.log('image created', newData)
      commit('CREATE_DATA')
      return newData
    } catch (error) {
      console.log('USER IS NOT AUTHORIZED', error)
    }
    setTimeout(function() {
      state.semaphores.created = false
    }, 2000)
  },
  async createImageStructures({ commit }, data) {
    state.loading = true
    try {
      // const newData = await DataStore.save(
      //     new Structure(data)
      // );
      const newData = await API.graphql(
        graphqlOperation(amplmutations.createImageStructures, { input: data })
      )
      console.log('image created', newData)

      commit('CREATE_DATA')
      return newData
    } catch (error) {
      console.log('USER IS NOT AUTHORIZED', error)
    }
    setTimeout(function() {
      state.semaphores.created = false
    }, 2000)
  },

  async delete({ commit }, data) {
    state.loading = true
    // const modelToDelete = await DataStore.query(Structure, data.id);
    // DataStore.delete(modelToDelete);
    await API.graphql(
      graphqlOperation(amplmutations.deleteImage, { input: { id: data.id } })
    )
    console.log('deleted', data.id)
    // TODO: check if the expected model is actually as it should be on remote
    //       as well. This is to confirm that no one else modified the same
    //       object while editing it locally.
    // DataStore.delete(modelToDelete, (p) => p.title('beginsWith', 'whaterver_it_should_be'));
    commit('DELETE_DATA')
    setTimeout(function() {
      state.semaphores.deleted = false
    }, 2000)
  },
  async deleteImageStructures({ commit }, data) {
    try {
      await API.graphql(
        graphqlOperation(amplmutations.deleteImageStructures, {
          input: { id: data }
        })
      )
      commit('DELETE_IMAGESTRUCTURE')
      console.log('🤞🏼Delete complete ')
    } catch (error) {
      console.log('Error on delete Image structure', error)
    }
  },
  async update({ commit }, data) {
    state.loading = true

    //const original = await DataStore.query(Structure, data.id);
    // const original = await API.graphql(graphqlOperation(queries.getStructure, { id: data.id }));
    // console.log(original)
    // await DataStore.save(Structure.copyOf(original, updated => {
    //     for (let p in data.updated) {
    //         updated[p] = data.updated[p];
    //     }
    // }));
    await API.graphql(
      graphqlOperation(amplmutations.updateImage, { input: data.updated })
    )

    commit('UPDATE_DATA')
    setTimeout(function() {
      state.semaphores.updated = false
    }, 2000)
  },
  async getImage({ state }, id) {
    console.log('ID Single Media ', id, state)
    const data = await API.graphql(
      graphqlOperation(queries.getImage, { id: id })
    )
    console.log('Single Image ', data)
    return data.data.getImage
  }
}

const mutations = {
  SET_DATA(state, data) {
    state.data = data
    state.initialFetchDone = true
  },

  CREATE_DATA(state) {
    state.loading = false
    state.semaphores.created = true
  },

  DELETE_DATA(state) {
    state.loading = false
    state.semaphores.deleted = true
  },
  DELETE_IMAGESTRUCTURE(state) {
    state.semaphores.deleted = true
  },

  UPDATE_DATA(state) {
    state.loading = false
    state.semaphores.updated = true
  },
  SET_SHOW_MODAL_STATUS(state, status) {
    state.showModalStatus = status
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
