const state = {
  toggleIsChecked: false,
  menu: [
    {
      id: 0,
      title: 'Dashboard',
      icon: 'mdi-view-dashboard',
      active: false,
      visible: true,
      route: '/dashboard'
    },
    {
      id: 1,
      title: 'Strutture',
      icon: 'mdi-domain',
      active: true,
      visible: true,
      route: '/properties/list'
      // children: [
      //   { title: "New", route: "/properties/list/new" },
      //   { title: "Published", route: "/properties/list/published" },
      //   { title: "Unpublished", route: "/properties/list/unpublished" },
      //   { title: "Archived", route: "/properties/list/archived" }
      // ]
    },
    {
      id: 2,
      title: 'Booking',
      icon: 'mdi-calendar-cursor',
      active: true,
      visible: true,
      route: '/bookings/list'
    },
    {
      id: 3,
      title: 'Contratti',
      icon: 'mdi-file-document-edit',
      active: false,
      visible: true,
      route: '/contracts'
    },
    {
      id: 4,
      title: 'Pagamenti',
      icon: 'mdi-bank-transfer-in',
      active: false,
      visible: true,
      route: '/payments'
    },
    {
      id: 5,
      title: 'Servizi',
      icon: 'mdi-toolbox-outline',
      active: false,
      visible: true,
      route: '/services'
    },
    {
      id: 6,
      title: 'Pagine',
      icon: 'mdi-file-document-multiple-outline',
      active: true,
      visible: true,
      route: '/pages/list'
    },
    {
      id: 7,
      title: 'Libreria Media',
      icon: 'mdi-image-multiple-outline',
      active: false,
      visible: true,
      route: '/media/list'
    }
  ]
}

const getters = {
  toggleIsChecked(state) {
    return state.toggleIsChecked
  },
  menu: state => {
    return state.menu.filter(m => m.visible)
  }
}

const actions = {
  toggleCheckSwitch({ commit }, data) {
    commit('SET_TOGGLE', data.checked)
  }
}

const mutations = {
  SET_TOGGLE(state, checked) {
    state.toggleIsChecked = checked
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
