// import { DataStore } from '@aws-amplify/datastore';
// import { Structure } from '@/models';

import * as customQueries from '@/graphql/custom-queries'
import * as amplmutations from '@/graphql/mutations'
import * as queries from '@/graphql/queries'
import { API, graphqlOperation } from 'aws-amplify'

const state = {
  loading: false,
  searchText: '',
  semaphores: {},
  initialFetchDone: false,
  data: [],
  subscription: null,
  structureStatus: ['published', 'unpublished', 'new', 'archived']
}

const getters = {
  getInitialFetchDone(state) {
    return state.initialFetchDone
  },
  getData(state) {
    return state.data
  },
  searchText: state => {
    return state.searchText
  }
}

const actions = {
  // async query({commit}) {
  //     const data =  await DataStore.query(Structure);
  //     commit('SET_DATA', data)
  // },
  async search({ state }) {
    try {
      const data = await API.graphql(
        graphqlOperation(customQueries.listStructures, {
          filter: {
            title: {
              contains: state.searchText
            }
          }
        })
      )
      console.log('Search results', data)
      return data.data.listStructures.items
    } catch (error) {
      console.log('Error', error)
    }
  },

  async list({ commit, state }, filters) {
    try {
      console.log('Listing properties ', state.searchText)
      let filter = {}
      if (filters !== undefined) {
        filter = {
          filter: {
            status: { eq: filters.status }
          },
          limit: 20
        }
        console.log('FILTRI', filter)

        //let st = filter.status =='new'
        if (filters.status == 'new') {
          delete filter.filter.status
          filter.filter.and = [
            { status: { ne: 'published' } },
            { status: { ne: 'unpublished' } },
            { status: { ne: 'archived' } }
          ]
          console.log('New + Null', filter)
        }
      }

      const data = await API.graphql(
        graphqlOperation(customQueries.listStructures, filter)
      )
      console.log('Data', data)
      commit('SET_DATA', data.data.listStructures.items)

      return data.data.listStructures.items
    } catch (error) {
      console.log('Errore List Properties ', error)
    }
  },
  async getStructure({ state }, id) {
    console.log('ID Single Structure ', id, state)
    const data = await API.graphql(
      graphqlOperation(queries.getStructure, { id: id })
    )
    console.log('Single Structure ', data)
    return data.data.getStructure
  },
  async create({ commit }, data) {
    state.loading = true
    try {
      // const newData = await DataStore.save(
      //     new Structure(data)
      // );
      const newData = await API.graphql(
        graphqlOperation(amplmutations.createStructure, { input: data })
      )
      console.log('structure created', newData)
      commit('CREATE_DATA')
      return newData
    } catch (error) {
      console.log('USER IS NOT AUTHORIZED', error)
    }
    setTimeout(function() {
      state.semaphores.created = false
    }, 2000)
  },
  // async createTagStructures({commit},data){
  //     state.loading = true,
  //     try {
  //         const newData = API.graphql(graphqlOperation(amplmutations.createTagStructures,{input:{

  //         }}))
  //     } catch (error) {
  //     console.log("Errore nella creazione TagStrucure",error)
  //     }
  // },

  async delete({ commit }, data) {
    state.loading = true
    // const modelToDelete = await DataStore.query(Structure, data.id);
    // DataStore.delete(modelToDelete);

    // await API.graphql(
    //   graphqlOperation(amplmutations.deleteStructure, {
    //     input: { id: data.id }
    //   })
    // );
    await API.graphql(
      graphqlOperation(amplmutations.updateStructure, {
        input: { id: data.id, status: 'archived' }
      })
    )
    // TODO: check if the expected model is actually as it should be on remote
    //       as well. This is to confirm that no one else modified the same
    //       object while editing it locally.
    // DataStore.delete(modelToDelete, (p) => p.title('beginsWith', 'whaterver_it_should_be'));
    commit('DELETE_DATA')
    // setTimeout(function (){ state.semaphores.deleted = false; }, 2000);
  },

  async update({ commit }, data) {
    state.loading = true
    await API.graphql(
      graphqlOperation(amplmutations.updateStructure, { input: data.updated })
    )
    commit('UPDATE_DATA')
    setTimeout(function() {
      state.semaphores.updated = false
    }, 2000)
  }
}

const mutations = {
  SET_DATA(state, data) {
    state.data = data
    state.initialFetchDone = true
  },

  CREATE_DATA(state) {
    state.loading = false
    state.semaphores.created = true
  },

  DELETE_DATA(state) {
    state.loading = false
    state.semaphores.deleted = true
  },

  UPDATE_DATA(state) {
    state.loading = false
    state.semaphores.updated = true
  },
  SET_SEARCHTEXT(state, text) {
    state.searchText = text
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
