import * as amplmutations from '@/graphql/mutations'
import * as queries from '@/graphql/queries'
import { API, graphqlOperation } from 'aws-amplify'

const state = {
  loading: false,
  searchText: '',
  semaphores: {},
  initialFetchDone: false,
  data: [],
  subscription: null
}

const getters = {
  getInitialFetchDone(state) {
    return state.initialFetchDone
  },
  getData(state) {
    return state.data
  },
  searchText: state => {
    return state.searchText
  }
}

const actions = {
  // async query({commit}) {
  //     const data =  await DataStore.query(Booking);
  //     commit('SET_DATA', data)
  // },
  async list({ commit, state }, filters) {
    try {
      console.log('Listing bookings ', state.searchText)

      let filter = {}
      if (filters !== undefined) {
        filter = {
          filter: {
            status: { eq: filters.status }
          },
          limit: 20
        }
        console.log('FILTRI', filter)

        //let st = filter.status =='new'
        if (filters.status == 'new') {
          delete filter.filter.status
          filter.filter.and = [
            { status: { ne: 'approved' } },
            { status: { ne: 'rejected' } }
          ]
          console.log('New + Null', filter)
        }
      }
      const data = await API.graphql(
        graphqlOperation(queries.listBookings, filter)
      )
      console.log('Data', data)
      commit('SET_DATA', data.data.listBookings.items)
      return data.data.listBookings.items
    } catch (error) {
      console.log('Errore List Properties ', error)
    }
  },
  async getBooking({ state }, id) {
    console.log('ID Single Booking ', id, state)
    const data = await API.graphql(
      graphqlOperation(queries.getBooking, { id: id })
    )
    console.log('Single Booking ', data)
    return data.data.getBooking
  },
  async create({ commit }, data) {
    state.loading = true
    try {
      const newData = await API.graphql(
        graphqlOperation(amplmutations.createBooking, { input: data })
      )
      console.log('bookin created', newData)
      commit('CREATE_DATA')
      return newData
    } catch (error) {
      // console.log('USER IS NOT AUTHORIZED', error)
      state.loading = false
      throw error.errors
    }
  },
  async delete({ commit }, data) {
    state.loading = true
    await API.graphql(
      graphqlOperation(amplmutations.updateBooking, {
        input: { id: data.id, status: 'archived' }
      })
    )
    commit('DELETE_DATA')
  },

  async update({ commit }, data) {
    try {
      state.loading = true
      await API.graphql(
        graphqlOperation(amplmutations.updateBooking, { input: data.updated })
      )
      commit('UPDATE_DATA')
      setTimeout(function() {
        state.semaphores.updated = false
      }, 2000)
      return true
    } catch (error) {
      console.log('Errore update', error)
    }
  },
  async isAvailable({ state }, checkingData) {
    state.loading = true
    try {
      let bookingsConflicts = API.graphql(
        graphqlOperation(queries.listBookings, {
          filter: {
            and: {
              structureID: {
                eq: checkingData.structureID
              },
              status: {
                eq: 'approved'
              },
              or: {
                and: {
                  startDate: { le: checkingData.startDate },
                  endDate: { ge: checkingData.endDate }
                },
                startDate: {
                  between: [checkingData.startDate, checkingData.endDate]
                },
                endDate: {
                  between: [checkingData.startDate, checkingData.endDate]
                }
              }
            }
          }
        })
      )
      return bookingsConflicts
    } catch (error) {
      console.log('errore isAvailable', error)
    } finally {
      state.loading = false
    }
  }
}

const mutations = {
  SET_DATA(state, data) {
    state.data = data
    state.initialFetchDone = true
  },

  CREATE_DATA(state) {
    state.loading = false
    state.semaphores.created = true
  },

  DELETE_DATA(state) {
    state.loading = false
    state.semaphores.deleted = true
  },

  UPDATE_DATA(state) {
    state.loading = false
    state.semaphores.updated = true
  },
  SET_SEARCHTEXT(state, text) {
    state.searchText = text
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
